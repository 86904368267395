import React, { useRef } from "react";

import { GalleryModal } from "../../component/modal";
import useTranslator from "../../hooks/use_translator";

const data = [
  {
    id: "1",
    title: "portrait",
    cover: "../../assets/images/porfolio/portret_cover.jpg",
    images: [
      {
        original: "../../assets/images/porfolio/Portreti/portreti_35.jpeg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_1.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_2.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_3.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_4.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_5.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_6.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_7.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_8.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_9.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_10.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_11.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_13.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_14.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_15.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_16.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_17.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_18.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_19.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_20.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_21.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_22.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_23.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_24.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_25.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_26.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_27.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_28.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_29.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_30.jpg",
      },
      {
        original: "../../assets/images/porfolio/Portreti/portreti_31.jpg",
      },
    ],
  },
  {
    id: "2",
    title: "commercial",
    cover: "../../assets/images/porfolio/komercijalna_cover.jpg",
    images: [
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_30.jpeg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_31.jpeg",
      },
      {
        original: "../../assets/images/porfolio/Komercijala/komercijalna_1.jpg",
      },
      {
        original: "../../assets/images/porfolio/Komercijala/komercijalna_2.jpg",
      },
      {
        original: "../../assets/images/porfolio/Komercijala/komercijalna_3.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_20.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_21.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_22.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_23.jpg",
      },
      {
        original: "../../assets/images/porfolio/Komercijala/komercijalna_7.jpg",
      },
      {
        original: "../../assets/images/porfolio/Komercijala/komercijalna_8.jpg",
      },
      {
        original: "../../assets/images/porfolio/Komercijala/komercijalna_9.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_10.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_11.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_12.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_13.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_14.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_15.jpg",
      },
      {
        original: "../../assets/images/porfolio/Komercijala/komercijalna_6.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_16.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_17.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_18.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_19.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_24.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_25.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_26.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_27.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_28.jpg",
      },
      {
        original:
          "../../assets/images/porfolio/Komercijala/komercijalna_29.jpg",
      },
    ],
  },
  {
    id: "3",
    title: "event",
    cover: "../../assets/images/porfolio/dogadjaji_cover.jpg",
    images: [
      {
        original: "../../assets/images/porfolio/dogadjaji/dogadjaji_1.jpg",
      },
      {
        original: "../../assets/images/porfolio/dogadjaji/dogadjaji_2.jpg",
      },
      {
        original: "../../assets/images/porfolio/dogadjaji/dogadjaji_3.jpg",
      },
      {
        original: "../../assets/images/porfolio/dogadjaji/dogadjaji_4.jpg",
      },
      {
        original: "../../assets/images/porfolio/dogadjaji/dogadjaji_5.jpg",
      },
      {
        original: "../../assets/images/porfolio/dogadjaji/dogadjaji_6.jpg",
      },
      {
        original: "../../assets/images/porfolio/dogadjaji/dogadjaji_7.jpg",
      },
      {
        original: "../../assets/images/porfolio/dogadjaji/dogadjaji_8.jpg",
      },
    ],
  },
  {
    id: "4",
    title: "sport",
    cover: "../../assets/images/porfolio/sportska_cover.jpg",
    images: [
      {
        original: "../../assets/images/porfolio/Sportska/sportska_1.jpg",
      },
      {
        original: "../../assets/images/porfolio/Sportska/sportska_2.jpg",
      },
      {
        original: "../../assets/images/porfolio/Sportska/sportska_3.jpg",
      },
      {
        original: "../../assets/images/porfolio/Sportska/sportska_4.jpg",
      },
      {
        original: "../../assets/images/porfolio/Sportska/sportska_5.jpg",
      },
      {
        original: "../../assets/images/porfolio/Sportska/sportska_6.jpg",
      },
    ],
  },
  {
    id: "5",
    title: "street",
    cover: "../../assets/images/porfolio/street_cover.jpg",
    images: [
      { original: "../../assets/images/porfolio/street/street_1.jpg" },
      { original: "../../assets/images/porfolio/street/street_2.jpg" },
      { original: "../../assets/images/porfolio/street/street_3.jpg" },
      { original: "../../assets/images/porfolio/street/street_4.jpg" },
      { original: "../../assets/images/porfolio/street/street_5.jpg" },
      { original: "../../assets/images/porfolio/street/street_6.jpg" },
      { original: "../../assets/images/porfolio/street/street_7.jpg" },
      { original: "../../assets/images/porfolio/street/street_8.jpg" },
      { original: "../../assets/images/porfolio/street/street_9.jpg" },
      { original: "../../assets/images/porfolio/street/street_10.jpg" },
      { original: "../../assets/images/porfolio/street/street_11.jpg" },
      { original: "../../assets/images/porfolio/street/street_12.jpg" },
      { original: "../../assets/images/porfolio/street/street_13.jpg" },
      { original: "../../assets/images/porfolio/street/street_14.jpg" },
      { original: "../../assets/images/porfolio/street/street_15.jpg" },
      { original: "../../assets/images/porfolio/street/street_16.jpg" },
      { original: "../../assets/images/porfolio/street/street_17.jpg" },
      { original: "../../assets/images/porfolio/street/street_18.jpg" },
      { original: "../../assets/images/porfolio/street/street_19.jpg" },
      { original: "../../assets/images/porfolio/street/street_20.jpg" },
      { original: "../../assets/images/porfolio/street/street_21.jpg" },
    ],
  },
  {
    id: "6",
    title: "fashion",
    cover: "../../assets/images/porfolio/fashion_cover.jpg",
    images: [
      { original: "../../assets/images/porfolio/fashion/fashion_1.jpg" },
      { original: "../../assets/images/porfolio/fashion/fashion_2.jpg" },
      { original: "../../assets/images/porfolio/fashion/fashion_3.jpg" },
      { original: "../../assets/images/porfolio/fashion/fashion_4.jpg" },
      { original: "../../assets/images/porfolio/fashion/fashion_5.jpg" },
      { original: "../../assets/images/porfolio/fashion/fashion_6.jpg" },
      { original: "../../assets/images/porfolio/fashion/fashion_7.jpg" },
      { original: "../../assets/images/porfolio/fashion/fashion_8.jpg" },
      { original: "../../assets/images/porfolio/fashion/fashion_9.jpg" },
    ],
  },
];

const PortfolioList = ({ column, styevariation }) => {
  const { translate } = useTranslator();

  const galleryModalRef = useRef();

  return (
    <>
      {data.map((value, index) => {
        const gallery = data[index].images;

        return (
          <div
            className={`${column}`}
            key={value.id}
            onClick={() => galleryModalRef.current?.openModal(gallery)}
          >
            <div className={`portfolio ${styevariation}`}>
              <div className="thumbnail-inner">
                <div className="thumbnail">
                  <img src={value.cover} alt={value.cover} />
                </div>
              </div>
              <div className="content">
                <div className="inner">
                  <h4>{translate(value.title)}</h4>
                  <div
                    className="portfolio-button rn-btn"
                    onClick={() =>
                      galleryModalRef.current?.openModal(value.images)
                    }
                  >
                    {translate("gallery")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <GalleryModal ref={galleryModalRef} />
    </>
  );
};
export default PortfolioList;
