import { createContext } from "react";

import sr from "../localization/sr.json";
import en from "../localization/en.json";

import usePersistState from "../hooks/use_persist_state";

export const LocalizationContext = createContext();

export const LocalizationProvider = ({ children }) => {
  const [language, setLanguage] = usePersistState("sr", "locale");

  return (
    <LocalizationContext.Provider
      value={{
        locale: language === "sr" ? sr : en,
        language,
        setLanguage,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
