import React from "react";
import ReactDOM from "react-dom/client";
import { LocalizationProvider } from "./context/localization_context";
import "./index.scss";
import PorfolioLanding from "./PorfolioLanding";

import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LocalizationProvider>
      <PorfolioLanding />
      <ToastContainer
        limit={3}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        theme="dark"
        draggable={false}
        transition={Zoom}
      />
    </LocalizationProvider>
  </React.StrictMode>
);
