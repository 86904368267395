import { toast } from 'react-toastify';

export const showSuccessNotify = (msg, toastId = null) =>
  toast.success(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    toastId,
  });

export const showErrorNotify = (msg, toastId = null) =>
  toast.error(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    toastId,
  });
