import React from "react";
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import useTranslator from "../hooks/use_translator";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/DinDem97" },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/din-demirovic-825545236",
  },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/photographerfromthehood",
  },
];

const Footer = () => {
  const { translate } = useTranslator();

  return (
    <div className="footer-style-2 ptb--30 " data-black-overlay="6">
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner">
              <div className="logo text-center text-sm-left mb_sm--20">
                <a
                  href="https://www.instagram.com/photographerfromthehood"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="../assets/images/logo/logo.png" alt="Logo images" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`} target="_blank" rel="noreferrer">
                      {val.Social}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p>{translate("copyright")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
