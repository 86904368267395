import React from "react";
import {
  FaUser,
  FaElementor,
  FaCalendarCheck,
  FaDumbbell,
  FaArchway,
  FaTshirt,
} from "react-icons/fa";
import useTranslator from "../../hooks/use_translator";

const data = [
  {
    icon: <FaUser />,
    title: "portrait",
    description: "portrait subtitle",
  },
  {
    icon: <FaElementor />,
    title: "commercial",
    description: "commercial subtitle",
  },
  {
    icon: <FaCalendarCheck />,
    title: "event",
    description: "event subtitle",
  },
  {
    icon: <FaDumbbell />,
    title: "sport",
    description: "sport subtitle",
  },
  {
    icon: <FaArchway />,
    title: "street",
    description: "street subtitle",
  },
  {
    icon: <FaTshirt />,
    title: "fashion",
    description: "fashion subtitle",
  },
];

const ServiceList = ({ column, item }) => {
  const { translate } = useTranslator();

  const ServiceContent = data.slice(0, item);

  return (
    <div className="row">
      {ServiceContent.map((val, i) => (
        <div className={`${column}`} key={i}>
          <div className="creative-service-div">
            <div className="service service__style--2">
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h3 className="title">{translate(val.title)}</h3>
                <p>{translate(val.description)}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ServiceList;
