import React, { useRef } from "react";
import Footer from "./component/footer";
import Header from "./component/header";
import Contact from "./elements/contact/Contact";
import PortfolioList from "./elements/porfolio/PortfolioList";
import ServiceList from "./elements/service/ServiceList";

import Typewriter from "typewriter-effect";
import useTranslator from "./hooks/use_translator";

const PorfolioLanding = () => {
  const { translate } = useTranslator();

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  return (
    <div className="active-dark">
      <Header sectionRefs={sectionRefs} />
      <div data-cy="section-wrapper">
        <div id="home" className="fix" ref={sectionRefs[0]}>
          <div className="slider-wrapper">
            {/* Start Single Slide */}
            <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner text-left`}>
                      <span>{translate("welcome")}</span>
                      <h1 className="title">
                        {`${translate("name")},`}
                        <br />
                        <Typewriter
                          options={{
                            strings: [translate("photographer"), "Freelancer"],
                            autoStart: true,
                            loop: true,
                          }}
                        />
                      </h1>
                      <a
                        href="https://www.instagram.com/photographerfromthehood"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h2>#Photographerfromthehood</h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Single Slide */}
          </div>
        </div>
        {/* Start About Area */}
        <div id="about" className="fix" ref={sectionRefs[1]}>
          <div className="about-area ptb--120  bg_color--1">
            <div className="about-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">
                  <div className="col-lg-5">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src="/assets/images/about/about-contact.jpg"
                        alt="About Images"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="about-inner inner">
                      <div className="section-title">
                        <h2 className="title">{translate("about me")}</h2>
                        <p className="description">
                          {translate("about title")}
                        </p>
                        <p className="description">
                          {translate("about subtitle")}
                        </p>
                        <p className="description">
                          {translate("about description")}
                        </p>
                        <p className="description">
                          {translate("about description 2")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End About Area */}

        {/* Start Service Area  */}
        <div id="service" className="fix" ref={sectionRefs[2]}>
          <div className="service-area creative-service-wrapper ptb--120 bg_color--5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">{translate("services")}</h2>
                    <p>{translate("services description")}</p>
                  </div>
                </div>
              </div>
              <div className="row creative-service">
                <div className="col-lg-12">
                  <ServiceList
                    item="6"
                    column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Portfolio Area */}
        <div id="portfolio" className="fix" ref={sectionRefs[3]}>
          <div className="portfolio-area ptb--120 bg_color--1">
            <div className="portfolio-sacousel-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                      <h2 className="title">Portfolio</h2>
                      <p>{translate("portfolio subtitle")}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <PortfolioList
                    styevariation="text-center mt--40"
                    column="col-lg-4 col-md-6 col-sm-6 col-12"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start COntact Area */}
        <div id="contact" className="fix" ref={sectionRefs[4]}>
          <div className="rn-contact-area ptb--120 bg_color--5">
            <Contact
              contactImages="../assets/images/about/about.jpg"
              contactTitle={translate("contact")}
            />
          </div>
        </div>
        {/* End COntact Area */}

        <Footer />
      </div>
    </div>
  );
};

export default PorfolioLanding;
