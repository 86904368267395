import { useContext, useCallback } from "react";
import { LocalizationContext } from "../context/localization_context";

const useTranslator = () => {
  const { locale, language, setLanguage } = useContext(LocalizationContext);

  const translate = useCallback(
    (word) => {
      return locale[word] ?? word;
    },
    [locale]
  );

  const changeLanguage = useCallback(
    (l) => {
      setLanguage(l);
    },
    [setLanguage]
  );

  return { translate, language, changeLanguage };
};

export default useTranslator;
