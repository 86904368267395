import React, { useState, forwardRef, useImperativeHandle } from "react";

import ReactModal from "react-modal";

import ImageGallery from "react-image-gallery";

import { AiOutlineClose } from "react-icons/ai";

const Modal = ({ isOpen, children, onRequestClose }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      preventScroll
      ariaHideApp={false}
      appElement={document.getElementById("root")}
      overlayClassName="react-modal-overlay"
      className="react-modal-wrapper"
    >
      {children}
      <AiOutlineClose
        size={30}
        onClick={() => onRequestClose()}
        color="white"
        className="modal-exit"
      />
    </ReactModal>
  );
};

export const GalleryModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [gallery, setGallery] = useState(null);

  useImperativeHandle(ref, () => ({
    openModal(gallery) {
      setIsOpen(true);
      setGallery(gallery);
    },
  }));

  return (
    <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
      <ImageGallery
        items={gallery}
        infinite
        lazyLoad
        showFullscreenButton={false}
        showBullets={false}
        showThumbnails={false}
        showIndex
        useBrowserFullscreen
      />
    </Modal>
  );
});
