import React, { useEffect } from "react";
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import { Scrollspy } from "@makotot/ghostui";
import useTranslator from "../hooks/use_translator";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/DinDem97" },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/din-demirovic-825545236",
  },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/photographerfromthehood",
  },
];

const Header = ({ sectionRefs }) => {
  const { language, changeLanguage, translate } = useTranslator();

  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  const closeMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };

  const scrollListener = () => {
    var value = window.scrollY;
    if (value > 100) {
      document.querySelector(".header--fixed").classList.add("sticky");
    } else {
      document.querySelector(".header--fixed").classList.remove("sticky");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);

  return (
    <header
      className={`header-area header-style-two header--fixed color-black`}
    >
      <div className="header-wrapper">
        <div className="d-flex align-items-center">
          <div className="logo">
            <a href="#home">
              <img
                src="/assets/images/logo/logo-symbol-dark.jpg"
                alt="Digital Agency"
                width={60}
              />
            </a>
          </div>
          <nav className="mainmenunav d-lg-block ml--50">
            <Scrollspy sectionRefs={sectionRefs} offset={-200}>
              {({ currentElementIndexInViewport }) => (
                <div>
                  <ul className="mainmenu" data-cy="nav-wrapper">
                    <li
                      onClick={() => closeMenuTrigger()}
                      data-cy={`nav-item`}
                      className={`${
                        currentElementIndexInViewport === 0 ? "is-current" : ""
                      }`}
                    >
                      <a href="#home">{translate("home")}</a>
                    </li>
                    <li
                      onClick={() => closeMenuTrigger()}
                      data-cy={`nav-item`}
                      className={`${
                        currentElementIndexInViewport === 1 ? "is-current" : ""
                      }`}
                    >
                      <a href="#about">{translate("about")}</a>
                    </li>
                    <li
                      onClick={() => closeMenuTrigger()}
                      data-cy={`nav-item`}
                      className={`${
                        currentElementIndexInViewport === 2 ? "is-current" : ""
                      }`}
                    >
                      <a href="#service">{translate("services")}</a>
                    </li>
                    <li
                      onClick={() => closeMenuTrigger()}
                      data-cy={`nav-item`}
                      className={`${
                        currentElementIndexInViewport === 3 ? "is-current" : ""
                      }`}
                    >
                      <a href="#portfolio">Portfolio</a>
                    </li>
                    <li
                      onClick={() => closeMenuTrigger()}
                      data-cy={`nav-item`}
                      className={`${
                        currentElementIndexInViewport === 4 ? "is-current" : ""
                      }`}
                    >
                      <a href="#contact">{translate("contact")}</a>
                    </li>
                  </ul>
                </div>
              )}
            </Scrollspy>
          </nav>
        </div>
        <div className="header-right">
          <div className="social-share-inner">
            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
              {SocialShare.map((val, i) => (
                <li key={i}>
                  <a href={`${val.link}`} target="_blank" rel="noreferrer">
                    {val.Social}
                  </a>
                </li>
              ))}
              <li>
                <span
                  className="header-localization"
                  onClick={() => {
                    if (language === "sr") {
                      changeLanguage("en");
                    } else {
                      changeLanguage("sr");
                    }
                  }}
                >
                  {language === "sr" ? "en" : "sr"}
                </span>
              </li>
            </ul>
          </div>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span onClick={menuTrigger} className="menutrigger text-white">
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={closeMenuTrigger} className="closeTrigger">
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
