import React, { useRef } from "react";
import useTranslator from "../../hooks/use_translator";

import emailjs from "@emailjs/browser";
import { showErrorNotify, showSuccessNotify } from "../../toast/toast";

const Contact = ({ contactTitle, contactImages }) => {
  const { translate } = useTranslator();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_GMAIL_SERVICE_ID,
        process.env.REACT_APP_GMAIL_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_GMAIL_USER_ID
      )
      .then(
        () => {
          showSuccessNotify(translate("email success"));
          document.getElementById("fieldset").disabled = true;
        },
        () => {
          showErrorNotify(translate("email error"));
        }
      );
  };

  return (
    <div className="contact-form--1">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">{contactTitle}</h2>
              <p className="description">
                {translate("contact subtitle 1")}{" "}
                <a href="tel:+381649804935">+381649804935</a>{" "}
                {translate("contact subtitle 2")}
                <a href="mailto:fotograf01143@gmail.com">
                  {" "}
                  fotograf01143@gmail.com
                </a>{" "}
              </p>
            </div>
            <div className="form-wrapper">
              <form ref={form} onSubmit={sendEmail}>
                <fieldset id="fieldset" className="fieldset">
                  <label htmlFor="item01">
                    <input
                      type="text"
                      name="name"
                      id="item01"
                      required
                      placeholder={translate("contact name")}
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="text"
                      name="email"
                      id="item02"
                      required
                      placeholder={translate("contact email")}
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      type="text"
                      name="subject"
                      id="item03"
                      placeholder={translate("contact subject")}
                    />
                  </label>
                  <label htmlFor="item04">
                    <textarea
                      type="text"
                      id="item04"
                      name="message"
                      required
                      placeholder={translate("contact message")}
                    />
                  </label>
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe"
                  >
                    {translate("contact send")}
                  </button>
                </fieldset>
              </form>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mb_md--30 mb_sm--30">
              <img src={`${contactImages}`} alt="trydo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
